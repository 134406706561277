<template>
  <div>

    <v-data-table
        :headers="headers"
        :items="rows"
        :items-per-page="5"
        class="elevation-1"
    ></v-data-table>
  </div>

</template>

<script>
import * as moment from "moment/moment";
export default {
  name: 'BookingList',

  props: {
    bookings: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    headers: [
      { text: 'ID', align: 'start', sortable: false, value: 'id'},
      { text: 'Room', value: 'room' },
      { text: 'Start', value: 'start_at' },
      { text: 'End', value: 'end_at' },
      { text: 'Description', value: 'description' },
      { text: 'Attenders', value: 'attenders' },
    ]
  }),

  computed: {
    rows() {
      let rows = [];

      this.bookings.forEach(function(e) {
        rows.push({
          id: e.id,
          description: e.description,
          start_at: moment.utc(e.start_at).local().format('YYYY-MM-DD HH:mm:ss'),
          end_at: moment.utc(e.end_at).local().format('YYYY-MM-DD HH:mm:ss'),
          room: e.room.name,
          attenders: e.users.map(a => a.name).join(', ')
        });
      });

      return rows;
    }
  }
}
</script>