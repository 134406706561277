<template>
  <v-datetime-picker
      :label="label"
      :value="value"
      @input="updateValue($event)"
      :text-field-props="textFieldProps"
  > </v-datetime-picker>
</template>

<script>
import Rule from '@/rules';
export default {
  name: 'DatetimePicker',

  props: ['value', 'rules', 'label'],

  data: () => ({
    textFieldProps: {}
  }),

  methods: {
    updateValue(event) {
      let message = '';
      this.rules.every((rule) => {
        message = rule(event);
        return !message.length;
      });

      if(!message.length) {
        this.$emit('input', event);
      }

      this.textFieldProps.errorMessages = message.length ? message : '';
    }
  }
}
</script>