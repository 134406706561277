<template>
  <v-container>
    <v-row v-if="can(READ_BOOKING)">
      <v-col cols="12">
        <h2>Hi {{ user.name }}</h2>
      </v-col>
      <v-col cols="12">
        <v-btn v-if="can(WRITE_BOOKING)" :to="{ name: 'Booking.Create'}">Booking</v-btn>
      </v-col>

      <v-col cols="12">
        <v-card class="mb-4">
          <v-card-text>
            <ul>
              <li class="red--text" v-for="(e, idx) in errors" :key="idx">{{ e }}</li>
            </ul>
            <v-form ref="search" v-model="valid">
              <v-row>
                <v-col cols="12" sm="12" md="4">
                  <v-text-field
                      dent
                      label="Keyword"
                      v-model="form.q"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <datetime-picker
                      label="Start At"
                      v-model="form.start_at"
                      :rules="[Rule.required, Rule.future.bind(this, new Date())]"
                  ></datetime-picker>
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <datetime-picker
                      label="End At"
                      v-model="form.end_at"
                      :rules="[Rule.required, Rule.future.bind(this, form.start_at)]"
                  ></datetime-picker>
                </v-col>

              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="blue darken-1"
                class="white--text"
                @click="search"
                :disabled="!valid"
            >
              Search
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>

      <v-col>
        <BookingList :bookings="bookings"/>
      </v-col>
    </v-row>

    <router-view @created:booking="createdBooking"></router-view>
  </v-container>
</template>

<script>
import Rule from '@/rules/index';
import { mapGetters, mapActions } from 'vuex';
import {WRITE_BOOKING, READ_BOOKING, can} from '@/middlewares/permission';
import BookingList from "@/components/BookingList";
import DatetimePicker from "@/components/DatetimePicker";
export default {
  name: 'MyBooking',
  components: {BookingList, DatetimePicker},
  data: () => ({
    Rule,
    can,
    WRITE_BOOKING,
    READ_BOOKING,
    form: {},
    errors: [],
    valid: true
  }),

  created() {
    this.setUser();
    this.setBookings();
  },
  computed: {
    ...mapGetters(['user', 'bookings'])
  },

  methods: {
    ...mapActions(['setUser', 'setBookings']),

    createdBooking() {
      this.setBookings();
    },

    search() {
      this.setBookings(this.form);
    }
  }

}
</script>